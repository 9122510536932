import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useStyles } from './PriceInsert.styles'
import useDiscountToken from '@talentinc/gatsby-theme-ecom/hooks/useDiscountToken'
import useDiscountCode from '@talentinc/gatsby-theme-ecom/hooks/useDiscountCode'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import { FormattedSKU } from '@talentinc/gatsby-theme-ecom/hooks/useSKUs'
import { MIN_AFFIRM_AMOUNT } from '@talentinc/gatsby-theme-ecom/types/constants'
import Affirm from '@talentinc/gatsby-theme-ecom/components/Affirm'
import { hasNonNullProperties } from '@talentinc/gatsby-theme-ecom/utils/utils'
import { SplitPaymentLink } from '@talentinc/gatsby-theme-ecom/components/TotalJobSeachLowLeadBands/NewSplitPaymentLinkV6'

type Props = {
  sku: FormattedSKU
  className?: string
}

export function PriceInsert(props: Props) {
  const { sku, className } = props
  const { cx, classes } = useStyles()

  const brand = useBrand()
  const discountCodeToken = useDiscountToken()
  const { data } = useDiscountCode(sku.plan_code, discountCodeToken)

  const formattedDiscount = useMemo(() => {
    if (!data) return null

    const nf = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: data.currency_code || 'USD',
      maximumFractionDigits: 0,
      currencyDisplay: 'narrowSymbol',
    })

    return nf.format(data.sticker_price - data.discount_value)
  }, [data])

  const PriceAffirm = useMemo(() => {
    if (
      sku.formattedAmount &&
      sku.plan_code &&
      !brand.name?.includes('TopCV') &&
      sku.amount > MIN_AFFIRM_AMOUNT
    ) {
      return <Affirm planCode={sku.plan_code} price={sku.amount} />
    }

    if (brand.isTopCvFr && hasNonNullProperties(sku.alternate_plan)) {
      return (
        <SplitPaymentLink
          planCode={sku.alternate_plan.plan_code}
          intervalTimes={sku.alternate_plan.interval_times}
          amount={sku.alternate_plan.amount}
        />
      )
    }

    return <Typography component="span">one-time payment only*</Typography>
  }, [sku, brand.isTopCvFr, brand.name])

  return (
    <Box className={cx(classes.root, className)}>
      <Box className={classes.price_wrapper}>
        {formattedDiscount ? (
          <>
            <Typography className={classes.price_primary}>
              {formattedDiscount}
            </Typography>
            <Typography className={classes.price_secondary}>
              {sku.formattedAmount}
            </Typography>
          </>
        ) : (
          <Typography className={classes.price_primary}>
            {sku.formattedAmount}
          </Typography>
        )}
      </Box>

      <Box className={classes.affirm_wrapper}>{PriceAffirm}</Box>
    </Box>
  )
}
