import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles<
  void,
  | 'title_wrapper'
  | 'title'
  | 'description'
  | 'title_description_wrapper'
  | 'selling_point'
  | 'cta_wrapper'
  | 'price_insert'
>()((theme, _, classes) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '38px 32px 24px 32px',
    borderRadius: '20px',
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      '0px 8px 20px -4px rgba(15, 56, 113, 0.12), 0px 2px 4px -1px rgba(15, 56, 113, 0.08)',

    /**
     * Hover
     * ----------------------------------------------------------------------------------------- */
    '&:hover > .benefits_hover': {
      display: 'block',
    },

    '&:nth-child(1) .benefits_hover': {
      left: '100%',

      '&::before': { left: '25%' },
      '&::after': { left: '25%' },
    },
    '&:nth-child(3) .benefits_hover': {
      left: 0,

      '&::before': { left: '75%' },
      '&::after': { left: '75%' },
    },

    /**
     * Overrides: Horizontal Card
     * ----------------------------------------------------------------------------------------- */
    '&.horizontal': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: '16px',
      padding: '24px 32px',

      [`& .${classes.title_description_wrapper}`]: {
        textAlign: 'start',
      },

      [`& .${classes.title_wrapper}`]: {
        justifyContent: 'flex-start',
      },

      [`& .${classes.cta_wrapper}`]: {
        width: 'fit-content',
        flexDirection: 'row',
        gap: '24px',

        '& .MuiButton-root': {
          width: '175px',
        },
      },
    },

    /**
     * Overrides: Mobile
     * ----------------------------------------------------------------------------------------- */
    [theme.breakpoints.down('md')]: {
      padding: '18px',
      boxShadow: 'none',
      border: '1px solid rgb(231, 234, 244)',
      alignItems: 'flex-start',
      flexDirection: 'column !important' as any,

      '&:hover > .benefits_hover': {
        display: 'none',
      },

      [`& .${classes.title_description_wrapper}`]: {
        width: '100%',
        textAlign: 'start',
      },

      [`& .${classes.title_wrapper}`]: {
        justifyContent: 'space-between !important',
        paddingBottom: '12px',
        marginBottom: '16px',
        borderBottom: '1px solid #E7EAF4',

        [`& .${classes.price_insert}`]: {
          display: 'flex',
          alignItems: 'flex-end !important',
        },
      },

      [`& .${classes.title}`]: {
        fontSize: '30px !important',
        fontWeight: '400',
        lineHeight: '1.5294',
      },

      [`& .${classes.description}`]: {
        fontSize: '18px !important',
        paddingBottom: '8px',
        lineHeight: '28px',
      },

      [`& .${classes.cta_wrapper}`]: {
        width: '100% !important',
        flexDirection: 'column !important',

        '& .MuiButton-root': {
          width: '100% !important',
        },

        [`& .${classes.price_insert}`]: {
          display: 'none',
        },
      },

      [`& .${classes.selling_point}`]: {
        paddingBottom: '16px',
      },
    },
  },
  title_description_wrapper: {
    textAlign: 'center',
  },
  title_wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    [`& .${classes.price_insert}`]: {
      display: 'none',
    },
  },
  title: {
    fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  description: {
    display: 'block',
    color: '#5A6377',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    minHeight: '60px',
  },
  cta_wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '8px',
    paddingTop: '16px',

    '& .MuiButton-root': {
      width: '100%',
    },
  },
  selling_points: {
    listStyle: 'none',
    padding: '0',
  },
  selling_point: {
    display: 'flex',
    paddingBottom: '12px',
    gap: '10px',
    fontSize: '16px',
    margin: '.375rem 0',

    '& span': {
      lineHeight: '20px',
    },
  },
  recommended_wrapper: {
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
  },
  recommended: {
    lineHeight: '0.5rem',
    letterSpacing: '.1em',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#52b37f',
    padding: '0.7rem 1rem 0.5rem',
    borderRadius: '0px 0px 12px 12px',
  },
  price_insert: {},
  benefits_hover: {
    display: 'none',
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    backgroundColor: 'white',
    padding: '32px',
    zIndex: 10,
    borderRadius: '24px',
    width: '210%',
    maxWidth: '800px',
    boxShadow:
      '0px 16px 64px -8px rgba(15, 56, 113, 0.18), 0px 6px 8px -2px rgba(15, 56, 113, 0.02)',

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)',
      borderStyle: 'solid',
    },

    '&::after': {
      top: '-80px',
      borderWidth: '40px',
      borderColor: 'transparent transparent rgb(249 249 249) transparent',
      zIndex: 12,
    },

    '&::before': {
      top: '-75px',
      borderWidth: '40px 50px 0 50px',
      borderColor: 'rgba(0, 0, 0, 0.2) transparent transparent transparent',
      filter: 'blur(25px)',
      zIndex: 11,
    },
  },
  benefits_hover_title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'start ',
    padding: '8px',
    alignItems: 'center',
  },
  benefits_grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '5px',

    '& p': {
      fontSize: '16px',
      color: '#656E83',
    },
  },
  benefit_item: {
    padding: '16px',

    '& img': {
      width: 20,
      height: 20,
      marginRight: 5,
    },
  },
}))
