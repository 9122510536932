export const Segment = {
  BlueCollar: 'blue_collar',
  Federal: 'federal',
  Military: 'military',
  Academic: 'academic',
  WhiteCollar_0_5: 'white_collar_0_5',
  WhiteCollar_6_10: 'white_collar_6_10',
  WhiteCollar_11: 'white_collar_11',
  Unknown: 'unknown',
} as const

export const PageKind = {
  JobSearch: 'Job Search',
  ResumeWritting: 'Resume Writing',
} as const
