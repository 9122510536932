import React, { useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material'
import { Check, ExpandLess, ExpandMore } from '@mui/icons-material'
import LinkButton from '@talentinc/gatsby-theme-ecom/components/Link/LinkButton'
import { useStyles } from './PackageCard.styles'
import { PriceInsert } from './PriceInsert'
import { PricePoint } from './data'
import { useBETelemetry } from '../../hooks/useTelemetry'

type Props = {
  pricePoint: PricePoint
  isRecommended?: boolean
  isHorizontal?: boolean
  isLoading?: boolean
  pageKind: string
  segment: string
}

export function PackageCard(props: Props) {
  const {
    pricePoint,
    isHorizontal,
    isRecommended,
    isLoading,
    pageKind,
    segment,
  } = props
  const { cx, classes } = useStyles()
  const [isShowingBenefits, setIsShowingBenefits] = useState(false)
  const telemetry = useBETelemetry()

  const trackButtonClick = (isHover: boolean) => {
    telemetry.track({
      event: 'click_order_now',
      properties: {
        label: pricePoint.sku.plan_short_name || 'Unknown Plan',
        plan_code: pricePoint.plan_code || 'Unknown Plan Code',
        hover: isHover ? 'True' : 'False',
        section: pageKind || 'Unknown Section',
        segment: segment || 'Unknown Segment',
      },
    })
  }

  return (
    <Box className={cx(classes.root, isHorizontal && 'horizontal')}>
      {isLoading ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#fff',
            width: '100%',
            height: '100%',
            zIndex: 999,
            borderRadius: '20px',
            overflow: 'hidden',
          }}
        >
          <Skeleton width="100%" height="100%" sx={{ transform: 'unset' }} />
        </Box>
      ) : null}

      {isRecommended ? (
        <Box className={classes.recommended_wrapper}>
          <Typography className={classes.recommended} variant="overline">
            Most Popular
          </Typography>
        </Box>
      ) : null}

      <Box className={classes.title_description_wrapper}>
        <Box className={classes.title_wrapper}>
          <Typography component="span" variant="h2" className={classes.title}>
            {pricePoint.sku.plan_short_name}
          </Typography>
          <PriceInsert className={classes.price_insert} sku={pricePoint.sku} />
        </Box>
        <Typography component="span" className={classes.description}>
          {pricePoint.description}
        </Typography>
      </Box>

      <Box className={classes.cta_wrapper}>
        <PriceInsert className={classes.price_insert} sku={pricePoint.sku} />
        <LinkButton
          href={`/purchase/${pricePoint.plan_code}`}
          onClick={() => trackButtonClick(false)}
        >
          Order Now
        </LinkButton>
      </Box>

      <ul className={classes.selling_points}>
        {pricePoint.selling_points.map((i) => (
          <li key={i} className={classes.selling_point}>
            <Check fontSize="small" color="primary" />
            <span>{i}</span>
          </li>
        ))}
      </ul>

      <Collapse in={isShowingBenefits} timeout="auto" unmountOnExit>
        {pricePoint.benefits.items.map((i) => (
          <Box
            key={pricePoint.plan_code + i.id}
            className={classes.benefit_item}
          >
            <Typography variant="h4" sx={{ fontSize: '19px' }}>
              <img
                src="https://images.ctfassets.net/7thvzrs93dvf/5jIr10503JPkWMZh6FG6JW/ea7943312b09500fc49445d213e7efb6/Color_Icons.png?w=57&h=56&q=90&fm=png"
                alt=""
              />
              {i.title}
            </Typography>
            <Typography>{i.description}</Typography>
          </Box>
        ))}
      </Collapse>

      <Button
        sx={{
          mt: 'auto !important',
          '&:hover': { boxShadow: 'none !important' },
          display: { sm: 'flex', md: 'none' },
        }}
        variant="text"
        onClick={() => setIsShowingBenefits((p) => !p)}
        fullWidth
        disableElevation
      >
        {isShowingBenefits ? (
          <>
            Hide Benefits
            <ExpandLess />
          </>
        ) : (
          <>
            Show Benefits
            <ExpandMore />
          </>
        )}
      </Button>

      {/* Section: Hover */}
      {pricePoint.benefits.items.length > 0 && !isLoading ? (
        <Box className={cx('benefits_hover', classes.benefits_hover)}>
          <Box className={classes.benefits_hover_title}>
            <Typography variant="h3">{pricePoint.benefits.title}</Typography>

            <LinkButton
              href={`/purchase/${pricePoint.plan_code}`}
              color="primary"
              size="small"
              onClick={() => trackButtonClick(true)}
            >
              Order Now
            </LinkButton>
          </Box>

          <Divider />

          <Box className={classes.benefits_grid}>
            {pricePoint.benefits.items.map((i) => (
              <Box
                key={pricePoint.plan_code + i.id}
                className={classes.benefit_item}
              >
                <Typography variant="h4" sx={{ fontSize: '19px' }}>
                  <img
                    src="https://images.ctfassets.net/7thvzrs93dvf/5jIr10503JPkWMZh6FG6JW/ea7943312b09500fc49445d213e7efb6/Color_Icons.png?w=57&h=56&q=90&fm=png"
                    alt=""
                  />
                  {i.title}
                </Typography>
                <Typography>{i.description}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
