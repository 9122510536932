import { Segment, PageKind } from './types'

export const BENEFITS = [
  {
    id: 1,
    title: 'Refreshed Resume',
    description:
      'Our experts will update your resume and deliver it to you in a new Applicant Tracking System compliant template.',
  },
  {
    id: 2,
    title: 'Recruiter Outreach / Distribution',
    description:
      "When you are ready we'll research, find, and send your resume to 200 recruiters actively looking for candidates with your skills and career goals.",
  },
  {
    id: 3,
    title: 'Career Services Platform',
    description:
      'Our platform and dedicated team handles everything - finding ideal jobs for you, applying for you, preparing you for interviews, and providing an analysis of salary offers to help you make the best decision.',
  },
  {
    id: 4,
    title: '10 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 10 applications within first 30 days of downloading your resume!',
  },
  {
    id: 5,
    title: 'World-class Resume Builder / Editor',
    description:
      'Get your new resume delivered in our easy to use resume builder. Quickly tailor resumes to specific jobs and change templates.',
  },
  {
    id: 6,
    title: 'Professionally Written Resume',
    description:
      "Our resumes are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    id: 7,
    title: '20 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 20 applications within first 30 days of downloading your resume!',
  },
  {
    id: 8,
    title: 'LinkedIn Makeover',
    description:
      'Our LinkedIn experts will review and rewrite your profile - 97% of employers use LinkedIn.',
  },
  {
    id: 9,
    title: 'Cover Letter',
    description:
      'Employers are 40% more likely to read a resume with a cover letter.',
  },
  {
    id: 10,
    title: 'Professionally Written Resume (Top 20%)',
    description:
      "Our resumes are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    id: 11,
    title: '40 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 40 applications within first 30 days of downloading your resume!',
  },
  {
    id: 12,
    title: 'Interview Coaching Session',
    description:
      '1 interview coaching session - Jobseekers who practice for interviews have a 96% higher success rate.',
  },
  {
    id: 13,
    title: '10-Min Video',
    description:
      '10-minute video from our professional resume reviewers. Targeted feedback on your resume, including areas to improve and actions to take.',
  },
  {
    id: 14,
    title: '40 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 20 applications within first 30 days of downloading your resume!',
  },
  {
    id: 15,
    title: '50 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 40 applications within first 30 days of downloading your resume!',
  },
  {
    id: 16,
    title: 'Interview Coaching Sessions',
    description:
      '2 interview coaching sessions - Jobseekers who practice for interviews have a 96% higher success rate.',
  },
  {
    id: 17,
    title: 'Professionally Written Resume (Top 1%)',
    description:
      "Our resumes are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    id: 18,
    title: 'Professionally Written Resume (Top 10%)',
    description:
      "Our resumes are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
]

export const PRICE_POINTS = {
  [Segment.BlueCollar]: {
    [PageKind.JobSearch]: [
      {
        plan_code: 'zDKPGvP',
        description: 'Dedicated job search support for your success',
        selling_points: [
          'Professionally Written Resume Draft',
          'We Apply For You: 10 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Fundamentals for Greatness Includes',
          items: [1, 2, 3, 4, 5],
        },
      },
      {
        plan_code: '5i3eGD6',
        description:
          'Professionally written resume to stand out, and dedicated job search support for your success',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Professional Edge Includes ',
          items: [6, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'vr1ICj4',
        description:
          'Expert-written professional story and complete job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Complete Path to Success Includes',
          items: [10, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: '2FTtipG',
        description:
          'Get your resume written by top 1% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
        selling_points: [],

        benefits: {
          title: 'Your Apex Path to Success Includes',
          items: [10, 11, 3, 8, 9, 2, 5],
        },
      },
    ],
    [PageKind.ResumeWritting]: [
      {
        plan_code: 'TixPqap',
        description: 'An expertly written draft resume to perfect your story',
        selling_points: [
          'Professionally Written Resume Draft',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Advantage Path to Success Includes',
          items: [1, 3, 5],
        },
      },
      {
        plan_code: 'JnCMhOK',
        description:
          'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Premium Path to Success Includes',
          items: [6, 8, 9, 3, 5],
        },
      },
      {
        plan_code: 'KYc6y9K',
        description:
          'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Ultimate Path to Success Includes',
          items: [6, 8, 9, 3, 5],
        },
      },
      {
        plan_code: 'rRAnYrA',
        description:
          'Looking for a major career upgrade? With a dedicated account manager and a Top 20% expert writer, this package helps you rise above the competition.',
        selling_points: [],
        benefits: {
          title: 'Your Elite Path to Success Includes',
          items: [10, 3, 8, 9, 12, 2, 5],
        },
      },
    ],
  },
  [Segment.Federal]: {
    [PageKind.JobSearch]: [
      {
        plan_code: 'xtug3FM',
        description: 'Dedicated job search support for your success',
        selling_points: [
          '10-minute Video Review of your Resume',
          'Professionally Written Resume Draft',
          'We Apply For You: 10 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Fundamentals for Greatness Includes',
          items: [13, 1, 2, 3, 4, 5],
        },
      },
      {
        plan_code: 'Y5JoB1P',
        description:
          'Professionally written resume to stand out, and dedicated job search support for your success',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Professional Edge Includes ',
          items: [6, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'OWRGmmk',
        description:
          'Expert-written professional story and complete job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter ',
          'Linkedin Makeover',
          'We Apply For You: 40 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Complete Path to Success Includes',
          items: [10, 14, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'ogpdR00',
        description:
          'Get your resume written by Top 20% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
        selling_points: [],
        benefits: {
          title: 'Your Apex Path to Success Includes',
          items: [10, 15, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
    [PageKind.ResumeWritting]: [
      {
        plan_code: 'z8087dD',
        description: 'An expertly written draft resume to perfect your story',
        selling_points: [
          '10-minute Video Review of your Resume',
          'Professionally Written Resume Draft',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Advantage Path to Success Includes',
          items: [13, 1, 3, 5],
        },
      },
      {
        plan_code: 'JMRDgkp',
        description:
          'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Premium Path to Success Includes',
          items: [6, 8, 9, 2, 3, 5],
        },
      },
      {
        plan_code: 'VxP9aNy',
        description:
          'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Interview Coaching Session',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Ultimate Path to Success Includes',
          items: [6, 8, 9, 12, 2, 3, 5],
        },
      },
      {
        plan_code: 'jTL5zJe',
        description:
          'Looking for a major career upgrade? With a dedicated account manager and a Top 20% expert writer, this package helps you rise above the competition.',
        selling_points: [],
        benefits: {
          title: 'Your Elite Path to Success Includes',
          items: [10, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
  },
  [Segment.Military]: {
    [PageKind.JobSearch]: [
      {
        plan_code: 'gwLlymI',
        description: 'Dedicated job search support for your success',
        selling_points: [
          '10-minute Video Review of your Resume',
          'Professionally Written Resume Draft',
          'We Apply For You: 10 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Fundamentals for Greatness Includes',
          items: [13, 1, 2, 3, 4, 5],
        },
      },
      {
        plan_code: 'ii6QpFV',
        description:
          'Professionally written resume to stand out, and dedicated job search support for your success',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Professional Edge Includes ',
          items: [6, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'RzCz1gq',
        description:
          'Expert-written professional story and complete job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter ',
          'Linkedin Makeover',
          'We Apply For You: 40 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Complete Path to Success Includes',
          items: [10, 14, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: '2JEnz3i',
        description:
          'Get your resume written by Top 20% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
        selling_points: [],
        benefits: {
          title: 'Your Apex Path to Success Includes',
          items: [10, 15, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
    [PageKind.ResumeWritting]: [
      {
        plan_code: 'z8087dD',
        description: 'An expertly written draft resume to perfect your story',
        selling_points: [
          '10-minute Video Review of your Resume',
          'Professionally Written Resume Draft',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Advantage Path to Success Includes',
          items: [13, 1, 3, 5],
        },
      },
      {
        plan_code: 'CK2zlud',
        description:
          'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Premium Path to Success Includes',
          items: [6, 8, 9, 2, 3, 5],
        },
      },
      {
        plan_code: 'vTt3RO3',
        description:
          'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Interview Coaching Session',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Ultimate Path to Success Includes',
          items: [6, 8, 9, 12, 2, 3, 5],
        },
      },
      {
        plan_code: 'IGuVBPE',
        description:
          'Looking for a major career upgrade? With a dedicated account manager and a Top 20% expert writer, this package helps you rise above the competition.',
        selling_points: [],
        benefits: {
          title: 'Your Elite Path to Success Includes',
          items: [10, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
  },
  [Segment.Academic]: {
    [PageKind.JobSearch]: [
      {
        plan_code: 'gwLlymI',
        description: 'Dedicated job search support for your success',
        selling_points: [
          '10-minute Video Review of your Resume',
          'Professionally Written Resume Draft',
          'We Apply For You: 10 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Fundamentals for Greatness Includes',
          items: [13, 1, 2, 3, 4, 5],
        },
      },
      {
        plan_code: 'Ebts4J8',
        description:
          'Professionally written resume to stand out, and dedicated job search support for your success',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Professional Edge Includes ',
          items: [6, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'akocSFR',
        description:
          'Expert-written professional story and complete job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter ',
          'Linkedin Makeover',
          'We Apply For You: 40 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Complete Path to Success Includes',
          items: [10, 14, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'o2EbRsa',
        description:
          'Get your resume written by Top 20% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
        selling_points: [],
        benefits: {
          title: 'Your Apex Path to Success Includes',
          items: [10, 15, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
    [PageKind.ResumeWritting]: [
      {
        plan_code: 'z8087dD',
        description: 'An expertly written draft resume to perfect your story',
        selling_points: [
          '10-minute Video Review of your Resume',
          'Professionally Written Resume Draft',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Advantage Path to Success Includes',
          items: [13, 1, 3, 5],
        },
      },
      {
        plan_code: 'JJwsEhe',
        description:
          'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Premium Path to Success Includes',
          items: [6, 8, 9, 2, 3, 5],
        },
      },
      {
        plan_code: 'IaSThof',
        description:
          'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Interview Coaching Session',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Ultimate Path to Success Includes',
          items: [6, 8, 9, 12, 2, 3, 5],
        },
      },
      {
        plan_code: 'usmhdWf',
        description:
          'Looking for a major career upgrade? With a dedicated account manager and a Top 20% expert writer, this package helps you rise above the competition.',
        selling_points: [],
        benefits: {
          title: 'Your Elite Path to Success Includes',
          items: [10, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
  },
  [Segment.WhiteCollar_0_5]: {
    [PageKind.JobSearch]: [
      {
        plan_code: 'zDKPGvP',
        description: 'Dedicated job search support for your success',
        selling_points: [
          'Professionally Written Resume Draft',
          'We Apply For You: 10 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Fundamentals for Greatness Includes',
          items: [1, 2, 3, 4, 5],
        },
      },
      {
        plan_code: '5i3eGD6',
        description:
          'Professionally written resume to stand out, and dedicated job search support for your success',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Professional Edge Includes ',
          items: [6, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'vr1ICj4',
        description:
          'Expert-written professional story and complete job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Complete Path to Success Includes',
          items: [10, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: '2FTtipG',
        description:
          'Get your resume written by top 1% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
        selling_points: [],
        benefits: {
          title: 'Your Apex Path to Success Includes',
          items: [10, 11, 3, 8, 9, 2, 5],
        },
      },
    ],
    [PageKind.ResumeWritting]: [
      {
        plan_code: 'TixPqap',
        description: 'An expertly written draft resume to perfect your story',
        selling_points: [
          'Professionally Written Resume Draft',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Advantage Path to Success Includes',
          items: [1, 3, 5],
        },
      },
      {
        plan_code: 'x8YNJvu',
        description:
          'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Premium Path to Success Includes',
          items: [6, 8, 9, 3, 5],
        },
      },
      {
        plan_code: 'e8dTeZJ',
        description:
          'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Ultimate Path to Success Includes',
          items: [6, 8, 9, 3, 5],
        },
      },
      {
        plan_code: 'sQ4cZjV',
        description:
          'Looking for a major career upgrade? With a dedicated account manager and a Top 20% expert writer, this package helps you rise above the competition.',
        selling_points: [],
        benefits: {
          title: 'Your Elite Path to Success Includes',
          items: [10, 3, 8, 9, 12, 2, 5],
        },
      },
    ],
  },
  [Segment.WhiteCollar_6_10]: {
    [PageKind.JobSearch]: [
      {
        plan_code: 'gwLlymI',
        description: 'Dedicated job search support for your success',
        selling_points: [
          '10-minute Video Review of your Resume',
          'Professionally Written Resume Draft',
          'We Apply For You: 10 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Fundamentals for Greatness Includes',
          items: [13, 1, 2, 3, 4, 5],
        },
      },
      {
        plan_code: 'sMMEvhD',
        description:
          'Professionally written resume to stand out, and dedicated job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Professional Edge Includes ',
          items: [10, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'H608kDp',
        description:
          'Expert-written professional story and complete job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter ',
          'Linkedin Makeover',
          'We Apply For You: 40 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Complete Path to Success Includes',
          items: [10, 14, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'fgM8bYX',
        description:
          'Get your resume written by Top 20% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
        selling_points: [],
        benefits: {
          title: 'Your Apex Path to Success Includes',
          items: [10, 15, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
    [PageKind.ResumeWritting]: [
      {
        plan_code: 'U0qhrDj',
        description: 'An expertly written draft resume to perfect your story',
        selling_points: [
          'Professionally Written Resume Draft',
          'Cover Letter',
          'Linkedin Makeover',
          'Career Services Platform',
          'World-class Resume Builder/Editor',
        ],
        benefits: {
          title: 'Your Advantage Path to Success Includes',
          items: [1, 8, 9, 3, 5],
        },
      },
      {
        plan_code: 'q5yxMDL',
        description:
          'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Premium Path to Success Includes',
          items: [6, 8, 9, 2, 3, 5],
        },
      },
      {
        plan_code: 'sQ4cZjV',
        description:
          'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter',
          'Linkedin Makeover',
          'Interview Coaching Session',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Ultimate Path to Success Includes',
          items: [10, 8, 9, 12, 3, 5],
        },
      },
      {
        plan_code: 'wp202Mb',
        description:
          'Looking for a major career upgrade? With a dedicated account manager and a Top 20% expert writer, this package helps you rise above the competition.',
        selling_points: [],
        benefits: {
          title: 'Your Elite Path to Success Includes',
          items: [10, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
  },
  [Segment.WhiteCollar_11]: {
    [PageKind.JobSearch]: [
      {
        plan_code: '8lrtt61',
        description: 'Dedicated job search support for your success',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Fundamentals for Greatness Includes',
          items: [6, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'vtHuaUY',
        description:
          'Professionally written resume to stand out, and dedicated job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Professional Edge Includes ',
          items: [10, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'RlqFsM',
        description:
          'Expert-written professional story and complete job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter',
          'Linkedin Makeover',
          'Interview Coaching Session',
          'We Apply For You: 40 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Complete Path to Success Includes',
          items: [10, 14, 3, 8, 9, 12, 2, 5],
        },
      },
      {
        plan_code: 'RhKL1Ey',
        description:
          'Get your resume written by Top 20% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
        selling_points: [],
        benefits: {
          title: 'Your Apex Path to Success Includes',
          items: [10, 15, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
    [PageKind.ResumeWritting]: [
      {
        plan_code: 'U0qhrDj',
        description: 'An expertly written draft resume to perfect your story',
        selling_points: [
          'Professionally Written Resume Draft',
          'Cover Letter',
          'Linkedin Makeover',
          'Career Services Platform',
          'World-class Resume Builder/Editor',
        ],
        benefits: {
          title: 'Your Advantage Path to Success Includes',
          items: [1, 8, 9, 3, 5],
        },
      },
      {
        plan_code: 'AWxlCd2',
        description:
          'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Premium Path to Success Includes',
          items: [10, 8, 9, 2, 3, 5],
        },
      },
      {
        plan_code: 'rJE2RBK',
        description:
          'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter',
          'Linkedin Makeover',
          'Interview Coaching Session',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Ultimate Path to Success Includes',
          items: [10, 8, 9, 12, 3, 5],
        },
      },
      {
        plan_code: 'acI3KbN',
        description:
          'Looking for a major career upgrade? With a dedicated account manager and a top 1% expert writer, this package helps you rise above the competition.',
        selling_points: [],
        benefits: {
          title: 'Your Elite Path to Success Includes',
          items: [17, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
  },
  [Segment.Unknown]: {
    [PageKind.JobSearch]: [
      {
        plan_code: '8lrtt61',
        description: 'Dedicated job search support for your success',
        selling_points: [
          'Professionally Written Resume',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
        ],
        benefits: {
          title: 'Your Fundamentals for Greatness Includes',
          items: [6, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'vtHuaUY',
        description:
          'Professionally written resume to stand out, and dedicated job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter',
          'Linkedin Makeover',
          'We Apply For You: 20 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Professional Edge Includes ',
          items: [10, 7, 3, 8, 9, 2, 5],
        },
      },
      {
        plan_code: 'RlqFsM',
        description:
          'Expert-written professional story and complete job search support for your success',
        selling_points: [
          'Written by Top 20% of our Writer Network',
          'Cover Letter',
          'Linkedin Makeover',
          'Interview Coaching Session',
          'We Apply For You: 40 Applications',
          'Recruiter Outreach',
          'World-class Resume Builder/Editor',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: 'Your Complete Path to Success Includes',
          items: [10, 14, 3, 8, 9, 12, 2, 5],
        },
      },
      {
        plan_code: 'RhKL1Ey',
        description:
          'Get your resume written by Top 20% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
        selling_points: [],
        benefits: {
          title: 'Your Apex Path to Success Includes',
          items: [10, 15, 3, 8, 9, 16, 2, 5],
        },
      },
    ],
    [PageKind.ResumeWritting]: [
      {
        plan_code: 'iFbBA45',
        description: 'Get traction with employers with a professional resume.',
        selling_points: [
          'ATS-optimized resume',
          'Career Services Platform',
          '60-day Interview guarantee',
        ],
        benefits: {
          title: '',
          items: [6, 3, 5],
        },
      },
      {
        plan_code: 'ZT5KxC4',
        description: 'Core documents to ensure you get the interview.',
        selling_points: [
          'ATS-Optimized resume',
          'Cover Letter',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: '',
          items: [6, 9, 3, 5],
        },
      },
      {
        plan_code: '1lNBpPM',
        description: 'Everything needed to stand out and get your target job.',
        selling_points: [
          'Top 20% of Writer Network',
          'ATS-Optimized resume',
          'Cover Letter',
          'LinkedIn Makeover',
          'Career Services Platform',
          '60-day Interview Guarantee',
        ],
        benefits: {
          title: '',
          items: [1, 8, 9, 3, 5],
        },
      },
      {
        plan_code: 'IoLhUUN',
        description:
          'Looking for a larger career upgrade? With a dedicated account manager and an expert-writer from the top 1% in our network, this package has everything you need to rise above the competition in the job market.',
        selling_points: [],
        benefits: {
          title: '',
          items: [18, 8, 9, 16, 3, 5],
        },
      },
    ],
  },
}
