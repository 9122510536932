import { useMemo } from 'react'
import {
  FormattedSKU,
  useAllSKUs,
} from '@talentinc/gatsby-theme-ecom/hooks/useSKUs'
import { Segment } from './types'
import { BENEFITS, PRICE_POINTS } from './data'
import { isSSR } from '../../../utils/env'

export type PricePoint = {
  plan_code: string
  description: string
  selling_points: string[]
  benefits: {
    title: string
    items: { id: number; title: string; description: string }[]
  }
  sku: FormattedSKU
}

type Args = {
  segment?: string
  pageKind: string
}

export function usePricePoints({ segment, pageKind }: Args) {
  const skus = useAllSKUs()
  const match =
    PRICE_POINTS[segment as keyof typeof PRICE_POINTS] ??
    PRICE_POINTS[Segment.Unknown]

  /**
   * NOTE: This is to ensure all the PRICE_POITNS have a proper SKU
   ----------------------------------------------------------------------*/
  if (isSSR) {
    const a = Object.values(PRICE_POINTS)
      .flatMap((i) => Object.values(i))
      .flat()
      .map((i) => i.plan_code)

    const b = skus.map((i) => i.plan_code)

    for (let i = 0; i < a.length; i++) {
      if (!b.includes(a[i])) {
        throw new Error('Unable to find SKU for plan_code ' + a[i])
      }
    }
  }

  const pricePoints: PricePoint[] = useMemo(
    () =>
      match[pageKind as keyof typeof match]?.map((i: any) => {
        const sku = skus.find(
          (j) => j.plan_code === i.plan_code
        ) as FormattedSKU

        return {
          ...i,
          benefits: {
            title: i?.benefits?.title,
            items: BENEFITS.filter((j) => i?.benefits?.items?.includes(j.id)),
          },
          sku,
        }
      }),
    [pageKind, skus, match]
  )

  return {
    pricePoints,
    match: PRICE_POINTS[segment as keyof typeof PRICE_POINTS]
      ? segment
      : Segment.Unknown,
  }
}
