import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import TrustPilotRatingWidget from '@talentinc/gatsby-theme-ecom/components/TrustPilot/TrustPilotRatingWidget'
import { useStyles } from './Hero.styles'
import MediaInsert from '@talentinc/gatsby-theme-ecom/components/ContentfulEntries/MediaInsert'
import { useBETelemetry } from '@talentinc/gatsby-theme-ecom/hooks/useTelemetry' // Import telemetry hook

export type Props = {
  pageKind: string
}

export function Hero(props: Props) {
  const { pageKind } = props
  const { classes } = useStyles()
  const telemetry = useBETelemetry()
  const [isTracked, setIsTracked] = useState(false)

  // Function to track video click
  const trackVideoClick = () => {
    if (!isTracked) {
      telemetry.track({
        event: 'click_video_testimonials',
        properties: {
          section: pageKind,
          label: 'learn how',
        },
      })
      setIsTracked(true)
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        {pageKind === 'Job Search' ? (
          <Box className={classes.title_wraper}>
            <Typography variant="h1" className={classes.title}>
              Let us manage your job search for you
            </Typography>
            <Typography variant="h6" className={classes.subtitle2}>
              Our team of experts will write your story, find jobs for you,
              apply for you, and prepare you to get the job you want.
            </Typography>
          </Box>
        ) : (
          <Box className={classes.title_wraper}>
            <Typography variant="h2" className={classes.subtitle1}>
              Professional Resume Writing Services
            </Typography>
            <Typography variant="h1" className={classes.title}>
              Land your next job faster.
            </Typography>
            <Typography variant="h6" className={classes.subtitle2}>
              Our services have helped over 1 million professionals land more
              interviews and get hired faster.
            </Typography>
          </Box>
        )}

        <Box onClick={trackVideoClick} sx={{ cursor: 'pointer' }}>
          <MediaInsert
            insert={
              {
                variant: 'Click to Play Button',
                buttonText: 'Learn How',
                assetFile: {
                  file: {
                    url:
                      pageKind === 'Job Search'
                        ? 'https://videos.ctfassets.net/7thvzrs93dvf/R43kMM887zy9xaAd9kREk/44e43f088bd57e9d5f7b0cac069b95fe/render.mp4'
                        : 'https://videos.ctfassets.net/7thvzrs93dvf/2YxMq2x2ax2cYcBGEf3Bla/b23c4c260f0e35f26c7fc5d4202da8d5/TR_Checkout_page_video_VO._29-04.mp4',
                  },
                },
              } as any
            }
          />
        </Box>

        <TrustPilotRatingWidget
          reference={
            { brand: 'TopResume', variant: 'FiveStars Alternative' } as any
          }
        />
      </Box>
      <Box className={classes.img}>
        <img
          src={
            pageKind === 'Job Search'
              ? 'https://images.ctfassets.net/7thvzrs93dvf/5VFYtXFpdRTxoMIArPYzOh/4a618b5a9b1203a146ff4db035591775/resume_writing_herosectionguy.png'
              : 'https://images.ctfassets.net/7thvzrs93dvf/1B0U1uZASvq7pFNOHaMyHL/64ea507048541e4cc815fb7af1dd0157/herosectiongirl.png'
          }
          alt=""
        />
      </Box>
    </Box>
  )
}
